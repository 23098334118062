<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            text=""
            default-href="/"
          />
        </ion-buttons>
        <ion-title> {{ $t('home.recently') }} </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-start ion-padding-end">
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh($event)"
      >
        <ion-refresher-content/>
      </ion-refresher>

      <div
        v-if="loading"
        class="is-flex ion-justify-content-center ion-align-items-center "
      >
        <IonSpinner />
      </div>

      <section class="tablet-centered">
        <SinglePodcast
          v-for="(stream, index) in recentlyPlayedFinal"
          :key="`stream-filtered-${index}`"
          :podcast-data="stream"
          :course-media="getCourseMedia(stream)"
          @click="openPodcast(stream)"
        />
      </section>

      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        :key="`x-${loadMoreDisabled}`"
        :disabled="loadMoreDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="`${$t('commonKeys.loading')}...`"
        />
      </ion-infinite-scroll>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed } from '@vue/reactivity';

import {
  IonContent,
  IonPage,
  IonToolbar,
  IonBackButton,
  IonTitle,
  IonHeader,
  IonButtons,

  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';
import {useStore} from "vuex";

import SinglePodcast from '@/components/common/SinglePodcast';

const store = useStore();

const loading = ref(false);


const recentlyPlayedData = computed(() => store.state.recentlyPlayed.recentlyPlayedPageList);
const sortedRecentlyData = computed(() => [...recentlyPlayedData.value]);
const recentlyPlayedFinal = computed(() => {
  const commonArray = [...sortedRecentlyData.value, ...store.state.player.recentlyPlayed];
  commonArray.sort((a, b) => b.progress_updated_timestamp - a.progress_updated_timestamp);
  return commonArray.filter((item, index, array) => {
    return array.findIndex(v => v.id === item.id) === index;
  });
});


const openPlayer = (stream, course) => {
  const startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
  store.dispatch('player/openPlayer', {
    course,
    company: course.company,
    playlist: course.streams,
    startAt,
  });
};
const openPodcast = (stream) => {
  store.dispatch('course/setCourseData', stream.course_id)
    .then((course) => {
      openPlayer(stream, course);
    });
};

const getCourseMedia = (stream) => {
  const rawFavoritesList = store.state.search.courses ?? [];
  return rawFavoritesList.find(course => course.id === stream.course_id)?.media ?? [];
};


const loadMoreDisabled = computed(() => {
  return store.state.recentlyPlayed.recentlyPlayedPageList.length >= store.state.recentlyPlayed.totalRecords;
});

const fetchRecentlyPlayed = (append = false) =>  store.dispatch('recentlyPlayed/setRecentlyPlayedPageList', append);

fetchRecentlyPlayed();

const loadMore = async (ev) => {
  await fetchRecentlyPlayed(true)
    .then(() => {
      ev.target.complete();
    });
};

const refresh = async (ev) => {
  return await fetchRecentlyPlayed(false)
    .finally(() => {
      ev.target.complete();
    });
};
</script>

<style scoped>
  ion-card {
    -webkit-box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
  }
</style>
